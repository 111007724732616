<template>
    <v-toolbar dense flat class="mb-4" color="transparent">
        <!--<v-btn v-if="hasGroupToView" text small @click="displayGroup" class="d-print-none">
            <span v-if="groupDisplaying" v-text="$t('issues.actions.hide_group')" />
            <span v-if="!groupDisplaying" v-text="$t('issues.actions.show_group')" />
        </v-btn>
        <v-toolbar-title class="mx-2 d-print-inline" v-text="project.label"></v-toolbar-title>-->

        <v-btn-toggle tile group v-show="issueSelected.length > 0" dense color="primary">
            <v-menu>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip top dark color="teal darken-4">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn small v-on="{ ...tooltip, ...menu }"><v-icon>mdi-bell-alert</v-icon></v-btn>
                        </template>
                        <span>{{ $t('issues.actions.severities_update') }}</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item
                        dense
                        v-for="(severity, index) in project.severities"
                        :key="index"
                        @click="changeAttribute('severity', severity.value)"
                    >
                        <v-list-item-title>{{ $t('lists.severities.' + severity.value) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip top dark color="teal darken-4">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn small v-on="{ ...tooltip, ...menu }"><v-icon>mdi-clipboard-list</v-icon></v-btn>
                        </template>
                        <span>{{ $t('issues.actions.status_update') }}</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item
                        dense
                        v-for="(status, index) in project.status"
                        :key="index"
                        @click="changeAttribute('status', status.name)"
                    >
                        <v-list-item-title>{{ $t('lists.status.' + status.name) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu>
                <template v-slot:activator="{ on: menu }">
                    <v-tooltip top dark color="teal darken-4">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn small v-on="{ ...tooltip, ...menu }"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <span>{{ $t('issues.actions.status_update') }}</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-subheader>{{ $t('issues.actions.header') }}</v-subheader>
                    <v-list-item dense v-for="(menu, index) in otherActions" :key="index" @click="editIssue(item)">
                        <v-list-item-title>{{ $t('issues.actions.' + menu) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn
            class="d-print-none"
            v-if="allowCreate"
            color="primary"
            v-text="$t('buttons.createIssue')"
            @click="createIssue"
        />
    </v-toolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'project-issues-toolbar',
    data: () => ({
        otherActions: ['edit', 'assigned', 'like'],
    }),
    props: {
        view: {
            required: true,
        },
        groupDisplaying: {
            default: true,
        },
        issueSelected: {
            default: () => [],
        },
        allowCreate: {
            default: true,
        },
    },
    components: {},
    mounted() {},
    computed: {
        ...mapGetters(['project']),
        hasGroupToView() {
            return this.view.groupCol;
        },
    },
    methods: {
        displayGroup() {
            this.$emit('groups-display');
        },
        createIssue() {
            this.$router.push({ name: 'project-issue-create', params: { project_id: this.project.id } });
        },
    },
};
</script>

<style></style>
