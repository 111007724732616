<template>
    <v-card>
        <v-card-subtitle v-text="$t('issues.actions.filter')"></v-card-subtitle>
        <v-card-text>
            <v-dialog v-model="dialog" persistent>
                <template v-slot:activator="{ on }">
                    <v-list dense nav>
                        <v-list-item>
                            <v-list-item-content>
                                <v-btn color="cyan darken-3" :outlined="!$vuetify.theme.dark" small v-on="on">
                                    <span v-text="$t('issues.actions.add_filters')" />
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                        <!--<v-list-item>
                            <v-list-item-content>
                                <ValidationProvider
                                    :name="$t('issues.attributes.number')"
                                    :rules="'decimal'"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        dense
                                        flat
                                        hide-details
                                        single-line
                                        outlined
                                        :label="$t('issues.search')"
                                        prepend-inner-icon="mdi-magnify"
                                        v-model="search"
                                        full-width
                                        v-on:keyup.enter="addNumberFilter"
                                        color="cyan darken-3"
                                        :error-messages="errors[0]"
                                        clearable
                                        @click:clear="removeNumberFilter"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-list-item-content>
                        </v-list-item>-->
                    </v-list>
                </template>
                <v-card>
                    <v-card-title v-text="$t('issues.actions.add_filters')"> </v-card-title>
                    <v-card-text>
                        <viewFilter v-model="filters" ref="viewFilter" />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="dialog = false">{{ $t('buttons.cancel') }}</v-btn>
                        <v-btn color="success" text @click="applyFilters">{{ $t('buttons.apply') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-list v-if="filters.length > 0" dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-btn depressed @click="removeFilters">
                            <v-icon left>mdi-close</v-icon>
                            {{ $t('issues.actions.remove_filters') }}
                        </v-btn>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="filter in filters" :key="filter.name">
                    <v-list-item-content>
                        {{ filter.label }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import viewFilter from '@/components/views/filter';

export default {
    name: 'project-issues-filter',
    data: () => ({
        dialog: false,
        filters: [],
        search: undefined,
    }),
    props: {},
    components: {
        viewFilter,
    },
    mounted() {
        this.initFilters();
    },
    computed: {
        ...mapGetters(['project']),
        sessionVariable() {
            return `${this.$route.path}#filters`;
        },
    },
    methods: {
        initFilters() {
            const filters = window.sessionStorage.getItem(this.sessionVariable);
            if (filters) {
                this.filters = JSON.parse(filters);
                this.applyFilters();
            }
        },
        applyFilters() {
            if (this.$refs.viewFilter) {
                this.$refs.viewFilter.addFilter();
            }

            this.dialog = false;

            window.sessionStorage.setItem(this.sessionVariable, JSON.stringify(this.filters));

            this.$emit('update-filters', this.filters);
        },
        removeFilters() {
            this.filters = [];
            this.applyFilters();
        },
        removeNumberFilter() {
            (this.search = undefined), this.$emit('update-filters', []);
        },
        addNumberFilter() {
            this.$emit('update-filters', [
                {
                    field: 'number',
                    operator: '$eq',
                    value: this.search,
                },
            ]);
        },
    },
};
</script>

<style></style>
