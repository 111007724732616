<template>
    <v-row v-if="project">
        <v-col>
            <v-card v-if="view">
                <v-card-text>
                    <issues-toolbar
                        :view.sync="view"
                        :group-displaying.sync="displayGroup"
                        v-on:groups-display="displayGroup = !displayGroup"
                        :issue-selected.sync="selected"
                        :allow-create="allowCreateIssue"
                    />
                    <issues-groups
                        v-if="displayGroup"
                        :view.sync="view"
                        :filters.sync="filters"
                        v-on:update-group="updateGroup"
                    />
                    <issues-view
                        :view.sync="view"
                        :filters.sync="filters"
                        :group.sync="group"
                        v-on:update-selected="updateSelected"
                        :allowSelect="allowSelectIssues"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2" class="d-print-none">
            <issues-filters v-on:update-filters="updateFilters" />
            <br />
            <view-list v-on:update-view="updateView" :views="views" :allow-create="allowCreatingView" />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { projectMemberMixin } from '@/mixins/member';
import viewList from '../../issues/issue/views';
import issuesToolbar from '../../issues/issue/project-toolbar';
import issuesFilters from '../../issues/issue/filters';
import issuesGroups from '../../issues/issue/groups';
import issuesView from '../../issues/issue/view';

export default {
    name: 'project-issues',
    data: () => ({
        displayGroup: true,
        view: undefined,
        filters: [],
        group: undefined,
        selected: [],
    }),
    mixins: [projectMemberMixin],
    components: {
        viewList,
        issuesToolbar,
        issuesFilters,
        issuesGroups,
        issuesView,
    },
    computed: {
        ...mapGetters(['project']),
        allowCreatingView() {
            return ['productManager'].some((r) => this.member.roles.includes(r));
        },
        allowCreateIssue() {
            return ['productManager', 'productOwner', 'developer', 'support', 'reporter'].some((r) =>
                this.member.roles.includes(r),
            );
        },
        allowSelectIssues() {
            return false;
        },
        views() {
            const viewsAllow = this.project.views.filter((v) => {
                if (v.system) {
                    return false;
                }
                if (v.security.users.length > 0) {
                    return v.security.users.includes(this.member.user);
                } else {
                    return v.security.roles.some((r) => this.member.roles.includes(r));
                }
            });
            return viewsAllow;
        },
    },
    methods: {
        updateView(view) {
            this.view = view;
        },
        updateFilters(filters) {
            this.filters = filters;
        },
        updateGroup(group) {
            this.group = group;
        },
        updateSelected(selected) {
            this.selected = selected;
        },
    },
};
</script>

<style></style>
