var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"mb-4",attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-btn-toggle',{directives:[{name:"show",rawName:"v-show",value:(_vm.issueSelected.length > 0),expression:"issueSelected.length > 0"}],attrs:{"tile":"","group":"","dense":"","color":"primary"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","dark":"","color":"teal darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-bell-alert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issues.actions.severities_update')))])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.project.severities),function(severity,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.changeAttribute('severity', severity.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lists.severities.' + severity.value)))])],1)}),1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","dark":"","color":"teal darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-clipboard-list")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issues.actions.status_update')))])])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.project.status),function(status,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.changeAttribute('status', status.name)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lists.status.' + status.name)))])],1)}),1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"top":"","dark":"","color":"teal darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('issues.actions.status_update')))])])]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('issues.actions.header')))]),_vm._l((_vm.otherActions),function(menu,index){return _c('v-list-item',{key:index,attrs:{"dense":""},on:{"click":function($event){return _vm.editIssue(_vm.item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('issues.actions.' + menu)))])],1)})],2)],1)],1),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{staticClass:"d-print-none",attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('buttons.createIssue'))},on:{"click":_vm.createIssue}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }